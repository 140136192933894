<template>
  <div class="page bg-white" id="reorganize">
    <div class="pageHeight">
      <el-steps v-if="butType == 0" :active="step" align-center style="margin-bottom: 20px">
        <el-step title="基本信息"></el-step>
        <el-step title="出库藏品"></el-step>
        <el-step title="预览"></el-step>
      </el-steps>

      <div v-show="step == 0 || step == 2">
        <div class="minTitle">基本信息</div>
        <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="200px"
                 size="small">
          <el-row>
            <el-col :span="11">
              <el-form-item label="出库原因" prop="reason">
                <el-select
                    :disabled="inputType"
                    v-model="inputForm.reason"
                    clearable
                    placeholder="请选择出库原因"
                    style="width: 100%"
                >
                  <el-option
                      @change="setReasonType"
                      v-for="item in this.$dictUtils.getDictList('outbound_reason').filter(item=>{return item.value != 0})"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="出库申请人" prop="applicantName">
                <el-input
                    v-model.trim="inputForm.applicantName"
                    placeholder="请输入出库申请人(限50字)"
                    maxlength="50"
                    :disabled="true"
                    clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="具体原因" prop="specificReasons">
                <el-input type="textarea" rows="1" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.specificReasons" maxlength="50"
                          placeholder="请输入具体原因(限50字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="出库时间" prop="operationDate">
                <el-date-picker
                    v-model="inputForm.operationDate"
                    :disabled="inputType"
                    type="date"
                    clearable
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    :picker-options="outBoundSet"
                    @change="timeComparison()"
                    placeholder="选择出库时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11" v-if="inputForm.reason != 2 && inputForm.reason != 6">
              <el-form-item label="预入库时间" prop="preDate">
                <el-date-picker
                    v-model="inputForm.preDate"
                    :disabled="inputType"
                    type="date"
                    clearable
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    @change="timeComparison()"
                    :picker-options="outBoundSet"
                    placeholder="选择预入库时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="备注说明" prop="remark">
                <el-input type="textarea" rows="4" resize="none" :disabled="inputType"
                          v-model.trim="inputForm.remark" maxlength="500"
                          placeholder="详细描述藏品出库的详细原因以及备注(限500字)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div v-show="step == 1 || step == 2">
        <div v-if="step != 2">
          <el-button size="small" type="primary" @click="addDataList(1,generalList)" icon="el-icon-plus">
            总账藏品
          </el-button>
          <el-button size="small" type="primary" @click="addDataList(2,auxiliaryList)" icon="el-icon-plus">
            辅助帐藏品
          </el-button>
          <!--                <el-button size="small" type="primary" @click="addDataList(3,immovableList)" icon="el-icon-plus">-->
          <!--                    不可移动文物-->
          <!--                </el-button>-->
        </div>

        <div class="flex_b_c"
             v-if="(butType == 1 && inputForm.reviewState != 1 && inputForm.reviewState != 0) || butType == 6 || butType == 7">
          <div class="minTitle">总账藏品清单</div>
          <div class="tabsCls">
            <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
              <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                           :name="item.value"></el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <div class="flex_b_c">
          <div>
            <div class="minTitle">总账藏品清单</div>
            <div style="margin-left: 25px">已选{{ generalList.length }}件/套 藏品</div>
          </div>
          <div v-show="step != 2">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(generalList,selectGeneralList,1)">
              批量删除
            </el-button>
          </div>
        </div>
        <el-table
            :data="generalList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            ref="multipleTable1"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeGenera"
        >
          <el-table-column :reserve-selection="true" type="selection" :selectable="canBeSelect" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.collectionData.carded == 0"
                     class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.collectionData.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.collectionData.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.collectionName }}
            </template>
          </el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.helpNum }}
            </template>
          </el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.generalNum }}
            </template>
          </el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.oneTypeName }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionData.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getTypeList(scope.row.collectionData.collectionType) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.integrityInfo }}
            </template>
          </el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.integrity }}
            </template>
          </el-table-column>

          <!--                <el-table-column v-if="butType == 4 || this.inputForm.reviewState == 2" prop="cStoreState" label="库存状态" show-overflow-tooltip>-->
          <!--                    <template slot-scope="scope">-->
          <!--                        {{ $dictUtils.getDictLabel("cStore_state", scope.row.collectionData.cStoreState, '-') }}-->
          <!--                    </template>-->
          <!--                </el-table-column>-->
          <el-table-column v-if="this.inputForm.reviewState == 2" prop="storeState"
                           label="出库状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getlistSelet(scope.row.storeState, storeStateList, 'state', 'stateName') }}
            </template>
          </el-table-column>
          <el-table-column v-if="this.inputForm.reviewState == 2" prop="applicationState"
                           label="申请状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getlistSelet(scope.row.applicationState, applicationStateList, 'state', 'stateName') }}
            </template>
          </el-table-column>
          <el-table-column v-if="this.inputForm.reviewState == 2" prop="operator"
                           label="出库/回库操作人" show-overflow-tooltip width="150px">
          </el-table-column>
          <el-table-column v-if="this.inputForm.reviewState == 2" prop="operatorTime"
                           label="出库/回库时间" width="150px" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.operatorTime | formatDate }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button v-show="step != 2" size="mini" type="text"
                         @click="deleteListData(generalList,[scope.row],1,scope.$index)">
                删除
              </el-button>
              <el-button v-if="butType != 0 && butType != 2" size="mini"
                         type="text" @click="detail(scope.row,1)">
                藏品详情
              </el-button>
              <el-button v-if="butType == 6 && scope.row.storeState == 5"
                         size="mini" type="text" @click="confirmOperation(0,scope.row)">
                确认出库
              </el-button>
              <el-button v-if="butType == 6 && scope.row.storeState == 5"
                         size="mini" type="text" @click="confirmOperation(1,scope.row)">
                确定回库
              </el-button>
              <el-button v-if="butType == 7 && scope.row.storeState == 6"
                         size="mini" type="text" @click="confirmOperation(2,scope.row)">
                确定收货
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="flex_b_c">
          <div>
            <div class="minTitle">辅助账藏品清单</div>
            <div style="margin-left: 25px">已选{{ auxiliaryList.length }}件/套 藏品</div>
          </div>
          <div v-show="step != 2">
            <el-button icon="el-icon-delete" size="small" type="danger"
                       @click="deleteListData(auxiliaryList,selectAuxiliaryList,2)">
              批量删除
            </el-button>
          </div>
        </div>
        <el-table
            :data="auxiliaryList"
            size="small"
            style="padding-left: 20px"
            height="300px"
            class="table"
            ref="multipleTable2"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeAuxiliary"
        >
          <el-table-column :reserve-selection="true" type="selection" :selectable="canBeSelect" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="图标" width="120px">
            <template slot-scope="scope">
              <div class="flex_a_c">
                <div v-if="scope.row.collectionData.carded == 0"
                     class="icon-wenwukapian iconfont listIcon"></div>
                <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.collectionData.picMasterViewUrl"
                    :fit="'cover'"
                    :preview-src-list="[scope.row.collectionData.picMasterViewUrl]"
                >
                  <div slot="error" class="image-slot">
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="require('@/assets/img/default.png')"
                        :fit="'cover'">
                    </el-image>
                  </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="collectionName" label="藏品名称"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.collectionName }}
            </template>
          </el-table-column>
          <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.helpNum }}
            </template>
          </el-table-column>
          <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.generalNum }}
            </template>
          </el-table-column>
          <el-table-column prop="oneTypeName" label="藏品分类" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.oneTypeName }}
            </template>
          </el-table-column>
          <el-table-column prop="leaveList" label="级别" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLeaveList(scope.row.collectionData.collectionLevel) }}
            </template>
          </el-table-column>
          <el-table-column prop="identified" label="是否鉴定" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.identified == 0 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getTypeList(scope.row.collectionData.collectionType) }}
            </template>
          </el-table-column>
          <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.integrityInfo }}
            </template>
          </el-table-column>
          <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.collectionData.integrity }}
            </template>
          </el-table-column>
          <!--                <el-table-column v-if="butType == 4 || this.inputForm.reviewState == 2" prop="cStoreState" label="库存状态" show-overflow-tooltip>-->
          <!--                    <template slot-scope="scope">-->
          <!--                        {{ $dictUtils.getDictLabel("cStore_state", scope.row.collectionData.cStoreState, '-') }}-->
          <!--                    </template>-->
          <!--                </el-table-column>-->
          <el-table-column v-if="this.inputForm.reviewState == 2" prop="storeState"
                           label="出库状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getlistSelet(scope.row.storeState, storeStateList, 'state', 'stateName') }}
            </template>
          </el-table-column>
          <el-table-column v-if="this.inputForm.reviewState == 2" prop="applicationState"
                           label="申请状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getlistSelet(scope.row.applicationState, applicationStateList, 'state', 'stateName') }}
            </template>
          </el-table-column>
          <el-table-column v-if="this.inputForm.reviewState == 2" prop="operatorName"
                           label="出库/回库操作人" show-overflow-tooltip width="150px"></el-table-column>
          <el-table-column v-if="this.inputForm.reviewState == 2" prop="operatorTime"
                           label="出库/回库时间" width="150px" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.operatorTime | formatDate }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button v-show="step != 2" size="mini" type="text"
                         @click="deleteListData(auxiliaryList,[scope.row],2,scope.$index)">
                删除
              </el-button>
              <el-button v-if="butType != 0 && butType != 2" size="mini"
                         type="text" @click="detail(scope.row,2)">
                藏品详情
              </el-button>
              <el-button v-if="butType == 6 && scope.row.storeState == 5"
                         size="mini" type="text" @click="confirmOperation(0,scope.row)">
                确认出库
              </el-button>
              <el-button v-if="butType == 6 && scope.row.storeState == 5"
                         size="mini" type="text" @click="confirmOperation(1,scope.row)">
                确定回库
              </el-button>
              <el-button v-if="butType == 7 && scope.row.storeState == 6"
                         size="mini" type="text" @click="confirmOperation(2,scope.row)">
                确定收货
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div v-show="step == 0 || step == 2" style="margin-top: 10px">
      <div class="text_center" v-if="step != 2">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(0)">下一步</el-button>
      </div>
    </div>
    <div v-show="step == 1 || step == 2" style="margin-top: 10px">
      <div class="text_center" v-if="step == 1">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
        <el-button size="small" type="primary" @click="nextStep(0)">预览</el-button>
      </div>

      <div class="text_center"
           v-else-if="step == 2 && butType != 1 && butType != 4 && butType != 5 && butType != 6 && butType != 7">
        <el-button size="small" @click="returnPage(1)">取消</el-button>
        <el-button size="small" type="primary" @click="nextStep(1)">上一步</el-button>
        <el-button size="small" type="primary" @click="saveAsDraft(1)">提交</el-button>
      </div>
      <div class="text_center" v-else>
        <el-button v-show="!see"
                   v-if="butType == 1 || butType == 4 || butType == 5 || butType == 6 || butType == 7"
                   size="small" type="primary" @click="switchData(1)">上一条
        </el-button>
        <el-button v-show="!see"
                   v-if="butType == 1 || butType == 4 || butType == 5 || butType == 6 || butType == 7"
                   size="small" type="primary" @click="switchData(0)">下一条
        </el-button>
        <el-button size="small" v-if="butType == 4 && see != 1 && this.inputForm.reviewState == 0"
                   type="primary"
                   @click="through(3)">通过
        </el-button>
        <el-button size="small" v-if="butType == 4 && see != 1 && this.inputForm.reviewState == 0"
                   type="danger"
                   @click="through(2)">驳回
        </el-button>
        <el-button size="small" @click="returnPage(1)">关闭</el-button>
        <el-button size="small" v-if="butType == 6" @click="confirmOperation(0)"
                   :disabled="!(selectGeneralList.length + selectAuxiliaryList.length)" type="primary">确定出库
        </el-button>
        <el-button size="small" v-if="butType == 6" @click="confirmOperation(1)"
                   :disabled="!(selectGeneralList.length + selectAuxiliaryList.length)" type="primary">确定回库
        </el-button>
        <el-button size="small" v-if="butType == 7" @click="confirmOperation(2)"
                   :disabled="!(selectGeneralList.length + selectAuxiliaryList.length)" type="primary">确定收货
        </el-button>
      </div>
    </div>
    <collList ref="collList"></collList>
    <confirm-outbound @chuKuad="getRowDetail()" ref="confirmOutbound"></confirm-outbound>
    <reviewSuggestions ref="suggestions" @updataStatus="returnPage(1)"></reviewSuggestions>
  </div>
</template>

<script>
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";
import collList from "@/views/modules/collection/business/outBound/collList.vue";
import ConfirmOutbound from "@/views/modules/collection/business/outBound/confirmOutbound.vue";

export default {
  name: "addOutbound",
  components: {ConfirmOutbound, reviewSuggestions, collList},
  data() {
    return {
      id: '',
      step: 0,
      inputForm: {
        reason: '',
        applicant: '',
        applicantName: '',
        specificReasons: '',
        operationDate: '',
        remark: '',
      },
      rules: {
        reason: [
          {required: true, message: '请选择出库原因', trigger: 'change'}
        ],
        applicantName: [
          {required: true, message: '请输入出库申请人', trigger: 'blur'}
        ],
        specificReasons: [
          {required: true, message: '请输入具体原因', trigger: 'blur'}
        ],
        operationDate: [
          {required: true, message: '请选择出库时间', trigger: 'change'}
        ],
        preDate: [
          {required: true, message: '请选择预入库时间', trigger: 'change'}
        ],

      },

      generalList: [],
      generalList2: [],
      selectGeneralList: [],

      auxiliaryList: [],
      auxiliaryList2: [],
      selectAuxiliaryList: [],

      immovableList: [],
      selectImmovableList: [],

      registrationSearch: {},

      inputType: false,
      returnPageStatus: '',
      butType: "", //0新增 1详情 2修改 4审核 5入库详情 6出库 7入库
      switch: '',//区分上下条接口
      leaveList: [],
      typeList: [],
      storeStateList: [],

      outBoundSet: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },

      applicationStateList: [],
      selectTab: '99',
      tabsList: [
        {
          name: '全部',
          value: '99',
        },
        {
          name: '出库中',
          value: '5',
        },
        {
          name: '收货中',
          value: '6',
        },
        {
          name: '已出库',
          value: '7',
        },
        {
          name: '已回库',
          value: '8',
        },
      ],
      see: false,
    }
  },

  mounted() {
    this.id = this.$route.query.id
    this.see = this.$route.query.see
    if (!this.id) {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.inputForm.applicant = userInfo.id
      this.inputForm.applicantName = userInfo.name
    } else {
      this.getRowDetail()
    }

    this.butType = this.$route.query.butType
    this.switch = this.$route.query.switch
    if (this.butType == 1 || this.butType == 4 || this.butType == 5 || this.butType == 6 || this.butType == 7) {
      this.inputType = true
      this.step = 2
    }
    this.setPageTitle()

    this.getAllState()
  },

  methods: {
    //0征集藏品 1总账藏品 2辅助帐藏品 3不可移动文物
    addDataList(num, data) {
      this.$refs.collList.init(num, data, 1)
    },

    selectionChangeGenera(val) {
      this.selectGeneralList = val
    },

    selectionChangeAuxiliary(val) {
      this.selectAuxiliaryList = val
    },

    selectionChangeImmovable(val) {
      this.selectImmovableList = val
    },

    //删除
    deleteListData(data, selectData, num, indexrt) {
      if (indexrt == 0 || indexrt) {
        this.$confirm(`点击确定后，将会从列表中删除您选中的藏品数据?`, '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          data.splice(indexrt, 1)
          this.$refs['multipleTable' + num].clearSelection()
        })
      } else {
        if (selectData.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        this.$confirm(`点击确定后，将会从列表中删除您选中的藏品数据?`, '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          selectData.forEach(item2 => {
            data.forEach((item1, index) => {
              if (item1.collectionData.id == item2.collectionData.id) {
                data.splice(index, 1)
              }
            })
          })
          this.$refs['multipleTable' + num].clearSelection()
        })
      }
    },

    //详情
    detail(row, num) {
      this.$router.push({
        path: '/collection/accounts/addCollection',
        query: {
          butType: 3,
          id: row.snapshotId,
          archivesBasicDataId: row.collectionData.archivesBasicDataId,
          typePage: 'InOroutStorage',
          see: 1
        }
      })
    },

    getAllState() {
      this.$axios(this.api.collection.listLevelSelect).then(data => {
        if (data.status) {
          this.leaveList = data.data
        }
      })
      this.$axios(this.api.collection.listSelect).then(data => {
        if (data.status) {
          this.typeList = data.data
        }
      })
      this.$axios(this.api.collection.listItemOutBount, {}, 'get').then(data => {
        if (data.status) {
          this.storeStateList = data.data
        }
      })
      this.$axios(this.api.collection.listItemOrderApplication, {}, 'get').then(data => {
        if (data.status) {
          this.applicationStateList = data.data
        }
      })
    },

    getRowDetail() {
      this.$axios(this.api.collection.getCollectionWarehouseOrderById + this.id, {}, 'get').then(data => {
        if (data.status) {
          this.$refs['multipleTable1'].clearSelection()
          this.$refs['multipleTable2'].clearSelection()
          this.inputForm = data.data
          this.inputForm.reason = this.inputForm.reason + ''
          this.generalList = data.data.collectionGeneralOrderItems
          this.generalList2 = data.data.collectionGeneralOrderItems
          this.auxiliaryList = data.data.collectionHelpOrderItems
          this.auxiliaryList2 = data.data.collectionHelpOrderItems
          this.selectTab = '99'
          this.$nextTick(() => {
            this.$refs['multipleTable1'].doLayout()
            this.$refs['multipleTable2'].doLayout()
          })
        }
      })
    },

    getLeaveList(leave) {
      let data = this.leaveList.filter(item => {
        return item.level == leave
      })
      if (data.length) {
        return data[0].levelName
      }
    },

    getTypeList(type) {
      let data = this.typeList.filter(item => {
        return item.id == type
      })
      if (data.length) {
        return data[0].collectionTypeName
      }
    },

    //num 0出库 1回库 2收货
    confirmOperation(num, row) {
      if (row) {
        let label = '确定您已接收对应的藏品？'
        if (num == 1) {
          label = '确定没有出库，并进行回库操作！'
        }
        if (num == 2) {
          label = '确定所选藏品已出库，点击后所选出库状态变为已出库。'
        }
        this.$confirm(`${label}`, '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios(this.api.collection.collectionwarehouseorderOutbound, [{
            id: row.id,
            type: num,
          }], 'post').then(data => {
            if (data.status) {
              if (num == 0) {
                this.$message.success('出库成功')
              } else if (num == 1) {
                this.$message.success('回库成功')
              } else {
                this.$message.success('收货成功')
              }
              this.getRowDetail()
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      } else {
        this.$refs.confirmOutbound.init(this.id, num, this.selectGeneralList, this.selectAuxiliaryList, 1)
      }
    },

    //0下一步 1上一步
    nextStep(num) {
      if (num == 0) {
        if (this.step == 0) {
          this.$refs.inputForm.validate((valid) => {
            if (valid) {
              if (this.inputForm.preDate) {
                if (this.timeComparison()) {
                  this.step++;
                }
              } else {
                this.step++;
              }
            }
          })
        } else {
          if (this.step == 1) {
            if (this.generalList.length + this.auxiliaryList.length == 0) {
              this.$message.error('请至少选择一件藏品')
              return
            }
            this.step++;
            this.inputType = true
          }
        }
      } else {
        if (this.step == 2) {
          this.inputType = false
        }
        this.step--;
      }
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      this.$router.back()
    },

    handleClick(tab, event) {
      if (this.selectTab == 99) {
        this.generalList = this.generalList2
        this.auxiliaryList = this.auxiliaryList2
      } else {
        this.generalList = this.generalList2.filter(item => {
          return item.storeState == this.selectTab
        })
        this.auxiliaryList = this.auxiliaryList2.filter(item => {
          return item.storeState == this.selectTab
        })
      }
    },

    //列表展示
    getlistSelet(id, data, idName, name) {
      let seleRow = data.filter(item => {
        return item[idName] == id
      })
      if (seleRow.length != 0) {
        return seleRow[0][name]
      }
    },

    // type 1提交  0保存为草稿
    saveAsDraft(type) {
      let data = JSON.parse(JSON.stringify(this.inputForm))
      this.$axios(this.api.collection.collectionwarehouseorder, {
        ...data,
        orderType: 1,
        collectionWarehouseOrderItems: [
          ...this.generalList,
          ...this.auxiliaryList,
        ],
      }, 'post').then(data => {
        if (data.status) {
          this.$message.success(`${type == 0 ? '保存' : '提交'}成功！`)
          this.programExit = true
          this.returnPage()
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    //type 2驳回 3通过
    through(type) {
      this.$refs.suggestions.init(2, [this.inputForm], type, '', this.api.collection.collectionwarehouseorderRevd, '', '', 'id')
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      if (num == 0) {
        this.registrationSearch.current++
      } else {
        if (this.registrationSearch.current == 1) {
          this.$message.error('已是第一条')
        } else {
          this.registrationSearch.current--
        }
      }
      let fetchUrl = this.api.collection.listOrder
      let methodsType = 'post'
      if (this.switch == 1) {
        fetchUrl = this.api.collection.pendingHandle
        methodsType = 'get'
      }
      if (this.switch == 2) {
        fetchUrl = this.api.collection.handled
        methodsType = 'get'
      }
      if (this.switch == 3) {
        fetchUrl = this.api.collection.listOrderRecode
        methodsType = 'post'
      }
      if (this.switch == 4) {
        fetchUrl = this.api.collection.standingBook
        methodsType = 'post'
      }
      this.$axios(fetchUrl, this.registrationSearch, methodsType).then((res) => {
        if (res.status) {
          if (res.data && res.data.records.length) {
            this.id = res.data.records[0].id
            this.getRowDetail()
          } else {
            this.registrationSearch.current--
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    timeComparison() {
      if (this.inputForm.operationDate && this.inputForm.preDate) {
        if (this.toDateSize(this.inputForm.operationDate, this.inputForm.preDate)) {
          this.$message.error('预入库时间不能小于出库时间！')
          return false
        } else {
          return true
        }
      }
    },

    setReasonType() {
      if (this.inputForm.reason == 2 || this.inputForm.reason == 6) {
        this.inputForm.preDate = []
      }
    },

    //时间大小比较
    toDateSize(date1, date2) {
      date1 = new Date(date1).getTime()
      date2 = new Date(date2).getTime()
      if (date1 > date2) {
        return true
      } else {
        return false
      }
    },

    // 设置当前行是否禁用【可以选择】 参数row 传入当前行数据
    canBeSelect(row) {
      if (this.butType == 6) {
        if (row.storeState == 5) {
          return true; // 返回true 可以选择
        } else {
          return false; // 返回false 禁止选择
        }
      } else if (this.butType == 7) {
        if (row.storeState == 6) {
          return true; // 返回true 可以选择
        } else {
          return false; // 返回false 禁止选择
        }
      } else {
        return true; // 返回true 可以选择
      }
    },

    setPageTitle() {
      let name = '出库详情'
      if (this.butType == 0) {
        name = '新增出库申请'
      } else if (this.butType == 2) {
        name = '出库修改'
      } else if (this.butType == 5) {
        name = '出库详情'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },
  },

  //离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
      })
      next();
    } else if (this.butType == 0 || this.butType == 2) {
      this.$confirm("当前内容没有保存，您确定离开吗？", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            //进去别的页面
            if (this.returnPageStatus) {
              this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
              })
            }
            next();
          })
          .catch(() => {
            next(false);
          });
    } else {
      next();
    }
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}

.input-new-tag {
  width: 200px;
}

.input-new-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
}

.adsfaads {
  margin-bottom: 15px !important;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
  position: static !important;
}

.pageHeight {
  height: calc(100vh - 205px);
  overflow-y: auto;
}
</style>